import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";

import "../fonts/fonts-post.css";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { formatPostDate, formatReadingTime } from "../utils/helpers";
import { rhythm, scale } from "../utils/typography";

const systemFont = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`;

const BlogPostTemplate = (props) => {
	const { data, pageContext, location } = props;
	const post = data.markdownRemark;
	const siteTitle = get(props, "data.site.siteMetadata.title");
	const { previous, next } = pageContext;
	const lang = post.fields.langKey;
	const { html } = post;

	return (
		<Layout location={location} title={siteTitle}>
			<SEO
				lang={lang}
				title={post.frontmatter.title}
				description={post.frontmatter.spoiler}
				slug={post.fields.slug}
			/>
			<main>
				<article>
					<header>
						<h1 style={{ color: "var(--textTitle)" }}>{post.frontmatter.title}</h1>
						<p
							style={{
								...scale(-1 / 5),
								display: "block",
								marginBottom: rhythm(1),
								marginTop: rhythm(-4 / 5),
							}}
						>
							{formatPostDate(post.frontmatter.date, lang)}
							{` • ${formatReadingTime(post.timeToRead)}`}
						</p>
					</header>
					<div dangerouslySetInnerHTML={{ __html: html }} />
				</article>
			</main>
			<aside>
				<div style={{ margin: "90px 0 40px 0", fontFamily: systemFont }}>
					{/* <Signup /> */}
				</div>
				<nav>
					<ul
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-between",
							listStyle: "none",
							padding: 0,
						}}
					>
						<li>
							{previous && (<Link to={previous.fields.slug} rel="prev" style={{ marginRight: 20 }}>{`← ${previous.frontmatter.title}`}</Link>)}
						</li>
						<li>
							{next && (<Link to={next.fields.slug} rel="next">{`${next.frontmatter.title} →`}</Link>)}
						</li>
					</ul>
				</nav>
			</aside>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        spoiler
      }
      fields {
        slug
        langKey
      }
    }
  }
`;
